// DashNav.js
import { useContext } from 'react';
import { Link, useLocation, useNavigate} from 'react-router-dom';
import { FaBarsStaggered } from 'react-icons/fa6';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { avater, bell, ideas_logo } from '../assets';
import { Context } from '../contexts/context';
import { useSelector } from 'react-redux';


const DashNav = () => {
  let { state, dispatch } = useContext(Context);
  const {  user } = useSelector((state) => state.auth);

 
  // console.log('user ', user);
  // console.log('token ', token);
const navigate = useNavigate();
// const handleNavigate = () =>{
//   navigate('/dashboard/settings')
// }

  let toggleNavbar = () => {
    dispatch({ type: 'SET_TOGGLE_NAVBAR', payload: !state.toggleNavbar });
  };

  let toggleSidebar = () => {
    console.log('toggle sidebar');
    dispatch({ type: 'SET_TOGGLE_SIDEBAR', payload: !state.toggleSidebar });
  };

  const location = useLocation();

  const displayName = `${user?.first_name || 'Super Admin'}`;

 
  const getTitle = () => {
    if (location.pathname === '/dashboard') {
      return 'Dashboard';
    } 
    else if (location.pathname.includes('/dashboard/application')) {
      return 'Applications';
    }
   
    
    // Add more conditions for different routes as needed
    return '';
  };

  // const {user, loading} = useSelector((state) =>state.auth);


  return (
    <div className="z-50 fixed left-0 top-0 right-0 h-[100px] px-6 py-4 bg-[#f9f9f9] border-b border-neutral-200 justify-between items-center md:gap-[20px] inline-flex">
    <div className="xl:px-[50px] flex items-center gap-[16px] xl:gap-[100px] h-full">
      <Link to="/dashboard" className='w-[24px] lg:w-full' passto>
        
          <img className='w-[70%] md:w-[100%]' src={ideas_logo} alt="Logo" />
      
      </Link>
      <div onClick={toggleSidebar} className="md:hidden">
        <FaBarsStaggered className='text-[20px] lg:text-[24px]' />
      </div>
      <div onClick={toggleSidebar} className="hidden md:block cursor-pointer bg-white px-28 rounded-xl inset-1 ">
        <h1 className="font-bold text-[25px] xl:text-[38px] text-nowrap" >{getTitle()}</h1>
      </div>
    </div>
    <div className="flex gap-2 md:gap-5 items-center">
      <div className="border-2 border-solid relative h-[30px] w-[30px] md:w-[40px] lg:h-[40px] flex items-center justify-center rounded-[10px]">
        <img src={bell} alt="Notification Bell" className="w-[13.5px] lg:w-[20px]" />
        {/* <div className="absolute top-0 md:-top-1 bg-red-600  text-white w-4 h-4 md:w-4 md:h-4 -right-[2px] md:-right-1 rounded-full text-[9px] cursor-pointer flex justify-center items-center " ></div> */}
      </div>
      <div className="flex items-center gap-2 lg:gap-3">
        {/* <img className='w-6 md:w-[50px] rounded-3xl' src={user?.image} alt="Profile" /> */}
        <img className='w-[58px] h-[58px] rounded-full cursor-pointer' src={user?.imageUrl
 || avater } alt="Profile" />
       
        <div className="flex items-start flex-col">
          <h1 className='md:text-[16px] font-bold text-[12px] text-nowrap'>{displayName}</h1>
          {/* <p className='md:text-[14px] text-[10px] text-[#8f8f8f]'>User</p> */}
        </div>
      </div>
    </div>
  </div>
  );
};

export default DashNav;
