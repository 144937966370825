import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosClient from '../axiosClient';

const loadUserFromStorage = () => {
  const user = localStorage.getItem('user');
  const token = localStorage.getItem('token');
  return {
    user: user ? JSON.parse(user) : [],
    token: token ? token : null,
  };
};

export const login = createAsyncThunk('auth/login', async ({ email, password }, { rejectWithValue }) => {
    try {
        const response = await axiosClient.post(`/user/login`, { email, password });
        const { token, user } = response.data.data;

        localStorage.setItem('token', response.data.data.token);
        localStorage.setItem('user', JSON.stringify(user));
        console.log('response is ', response.data.data);
        return response.data.data;

    } catch (error) {
        
      
      // Check if error.response exists
      if (error.response) {
        return rejectWithValue(error.response.data);
    } else if (error.request) {
      return rejectWithValue({ message: 'An error occurred due to network connectivity, kindly check your internet connection and try again.' });

    } else {
        return rejectWithValue({ message: error.message });
    }
    }
});

export const getApplicationstatistics = createAsyncThunk(
  'auth/getApplicationstatistics',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = state.auth.token; // Adjust the path to your token

      console.log('token is ', token);
      const response = await axiosClient.get(`/admin/ideas-programme/statistics`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      console.log('response is stat', response.data);
      console.log('stat is', response.data);
      return response.data; 
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getApplication = createAsyncThunk(
  'auth/getApplications',
  async ({ page = 1, page_size = 10, search = '', }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = state.auth.token; // Adjust the path to your token

      const response = await axiosClient.get(`/admin/ideas-programme/applications`, {
        params: { page, page_size, search },
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data; 
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


    const authSlice = createSlice({
    name: 'auth',
    initialState: {
      //...loadUserFromStorage(),
      user: [],
      loading: false,
      error: null,
      token: null,
      applications : [],
      statistics: null, 
      beneError: null,
      previousRoute: '/',
      isAuthenticated: false,
      search: '',
        
    },
    reducers: {
        logout: (state) => {
          state.isAuthenticated = false;
          state.loading =false;
          state.user = null;
          state.token = null;
          state.applications =null;
          state.totalCount = null;
          
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          localStorage.removeItem('redirectPath');

        },
        savePreviousRoute: (state, action) => {
          state.previousRoute = action.payload;
        },
        loginSuccess: (state, action) => {
         
          state.isAuthenticated = true;
         
        },
        clearBeneError: (state) => {
          state.beneError = null;
          state.error = null;
        },
        setSearch: (state, action) => {
          state.search = action.payload;
        },
       
      
      },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loading = false;
                state.isAuthenticated =true;
                state.token = action.payload.token;
                state.user = action.payload.user;

            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message || action.error.message;
                state.isAuthenticated = false;
            })
            

            
            .addCase(getApplication.pending, (state) => {
                state.loading = true;
                state.isAuthenticated = true;
            })
            .addCase(getApplication.fulfilled, (state, action) => {
              state.totalCount = action.payload.meta.total;
                state.applications = action.payload.data;
                state.isAuthenticated = true;
                state.loading = false;
            })
            .addCase(getApplication.rejected, (state, action) => {
                state.loading = false;
                state.isAuthenticated = false;
                state.error = action.payload?.errors || action.error.errors;
            })
            .addCase(getApplicationstatistics.pending, (state) => {
                state.loading = true;
                state.isAuthenticated = true;
            })
            .addCase(getApplicationstatistics.fulfilled, (state, action) => {
                state.statistics = action.payload;
                state.isAuthenticated = true;
                state.loading = false;
            })
            .addCase(getApplicationstatistics.rejected, (state, action) => {
                state.loading = false;
                state.isAuthenticated = false;
                state.error = action.payload?.errors || action.error.errors;
            });



  
            
            
           
              
    },
});

export const { logout, savePreviousRoute, clearBeneError, setSearch, loginSuccess, setUserBalance } = authSlice.actions;

export default authSlice.reducer;