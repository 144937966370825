import { FiGrid } from "react-icons/fi";
import { ai_img, cybersecurity, data_science, digital_literacy, drone, facebook, ideas_logo, instagram_icon, intel_mobile, intelbox_logo, linkin_logo, marketing, robotic, threedesign, total_applicant, total_approved_applicant, total_decline_applicant, total_pending_applicant, ui_uxImg, uniccon_mobile, unicconLogo, world_logo, world_mobile, x_logo } from "../assets";
import { PiStudent } from "react-icons/pi";
import { AiOutlineLogout } from "react-icons/ai";

export const navLinks = [
{
path: "/",
label: "Home"

},
{
path: "/apply",
label: "Apply Now"

},


];

export const courses = [
{
    img: robotic,
    title: "Robotics and Automation."
},
{
    img: ai_img,
    title: "Artificial Intelligence"
},
{
    img: marketing,
    title: "Digital Marketing."
},
{
    img: drone,
    title: "Drones and UAV."
},
{
    img: ui_uxImg,
    title: "UI/UX Designs."
},
{
    img: data_science,
    title: "Data Science."
},
{
    img: cybersecurity,
    title: "Cybersecurity"
},
{
    img: digital_literacy,
    title: "Computer Operations"
},
{
    img: threedesign,
    title: "3D Designs."
},




];


export const partners = [
{
    img: world_logo,
    img2: world_mobile
},
{
    img: unicconLogo,
    img2: uniccon_mobile,
},
{
    img: intelbox_logo,
    img2: intel_mobile,
},


];


export const ideas = [

    {
        img: ideas_logo,
        text: `We welcome applications from innovative Nigerians ,  resident in Abuja, regardless of gender, disability, or any other background. `
    }
];
export const locations = [

    {
        title: "Location",
        text: `3rd, 4th and 5th Floor, Lanre Shittu 
House, Mabushi, Abuja. 
900108 `
    }
];

export const companes = [
    {
      title: "Company",
      links: [
        {
          label: "Privacy Policy",
          path: "/privacy"
        },
        {
          label: "Terms & Conditions",
          path: "/terms"
        },
      ]
    }
  ];
export const ContactUs = [
    {
      title: "Contact Us",
      contacts: [
        {
          phone: "+2347013284159",
          
        },
        {
          phone: "+2348057992424",
          
        },
      ]
    }
  ];
  
export const WebsiteLinks = [
    {
      title: "Website Links",
      links: [
        {
          label: "Home",
          path: "/",
          
        },
        {
          label: "Apply Now",
          path: "/apply",
          
        },
      ]
    }
  ];

  export const footerSocial = [

{
  path: 'https://x.com',
  img: x_logo
},
{
  path: 'https://x.com',
  img: linkin_logo
},
{
  path: 'https://facebook.com',
  img: facebook
},
{
  path: 'https://instagram.com',
  img: instagram_icon
},

  ];
  export const mainDashitems = [

{
  label: 'Total Applicants',
  img: total_applicant,
  number: '20,000'
},
{
  label: 'Total Pending Applicants',
  img: total_pending_applicant,
  number: '20,000'

},
{
  label: 'Total Applicant Approved',
  img: total_approved_applicant,
  number: '20,000'

},
{
  label: 'Total Decline Applicants',
  img: total_decline_applicant,
  number: '20,000'

},

  ];
  




  export const sidebarItems = [
    {
        icon: <FiGrid />,
        label: 'Dashboard',
        path: '/dashboard'
    },
    {
        icon: <PiStudent />,
        label: 'Applications',
        path: '/dashboard/application'
    },
    {
        icon: <AiOutlineLogout />,
        label: 'Logout',
        path: '#'
    },
    
];
