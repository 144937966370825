
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice from './authSlice';

const rootReducer = combineReducers({

  auth: authSlice
   
  });
  

  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth' ] // Persist both auth and history reducers
  };


  const persistedReducer = persistReducer(persistConfig, rootReducer);


  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types for serializability checks
          ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
        },
      }),
  });
  

  const persistor = persistStore(store);

export { store, persistor };
