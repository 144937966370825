import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { failure_icon, success_icon } from '../assets';

const SuccessAlert = ({ success, error, isVisible, onClose, path }) => {
  const dispatch = useDispatch();
  const boxRef = useRef();

  useEffect(() => {
    if (!isVisible) return;

    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible, dispatch]);

  const handleClose = async () => {
  
   
    if (onClose) onClose();
  };
 
  if (!isVisible) return null;

  return (
    <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-40'>
      <div ref={boxRef} className='bg-white p-8 gap-4 rounded-xl w-full mx-4 hd-[250px] md:w-[400px] h-[300px] flex flex-col items-center justify-center'>
        <div className='flex flex-col items-center'>
      {
        error && (
          <p className='text-red-700 font-semibold'>Failed</p>

        )
      }
      {
        success && (
          <p className='text-[#00A52C] font-semibold'>Successful</p>

        )
      }

          <p className='text-center text-[16px] leading-[21px]'>
            {success || error}
          </p>
        </div>
        <div className=''>

{
  success && (
    <img src={success_icon} alt='Success' />

  )
}      
{
  error && (
    <img src={failure_icon} alt='Failure' />

  )
}      

  </div>
        <a href={path}>
          <button
            className='py-2 px-10 bg-[#03813E] rounded-xl h-[50px] text-white shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300'
            onClick={handleClose}
          >
            Okay
          </button>
        </a>
      </div>
    </div>
  );
};

export default SuccessAlert;
