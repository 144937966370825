import { useParams } from "react-router-dom";
import { applicant, checkMarked } from "../assets"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axiosClient from "../axiosClient";
import ConfirmPopup from "./ConfirmPopup";
import SuccessAlert from "./SuccessAlert";
import Select from 'react-select'; 
import { getApplication } from "../Controllers/authSlice";

const ApplicationDetail = () => {

      const { id } = useParams();
      const { applications, token, user } = useSelector((state) => state.auth);
      const application = applications?.find((app) => app.id === parseInt(id));
      const [error, setError] =useState(null);
      const [popup, SetConfirmPopup] = useState(false);
      const [successPopup, setSuccessPopup] = useState(false);
      const [loading, setLoading] = useState(false);
      const [message, setMessage] = useState(null);
      const [courseOptions, setCourseOptions] = useState([]);
      const isAdmin = user?.roles?.some(role => role.name === "Super Admin");
      const canManageApplication = isAdmin || 
        (application.digital_skills_track?.name === "Computer Operations" || application.digital_skills_track?.name === "UI/UX Designs" ||
        application.digital_skills_track?.name === "Digital Marketing");
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [fullImageUrl, setFullImageUrl] = useState('');
      const [rotation, setRotation] = useState(0);
      const [course, setCourse] = useState('');
      const dispatch = useDispatch();
        const applicationTrack = application?.application_status === 'Accepted' && application?.digital_skills_track !== null;

      const acceptedApplication = application?.application_status
      const [actionType, setActionType] = useState(null);
      const digitalTrack = application?.digital_skills_track;
      const canAssignTrack = isAdmin && !digitalTrack;
          console.log('app is ', application)
    const customStyles = {
      control: (base, state) => ({
        ...base,
        backgroundColor: 'transparent',
        border: state.isFocused ? '0.78px solid #ffff' : '2px solid #000',
        height: '48px',
        boxShadow: state.isFocused ? '0 0 0 0.78px #03813E' : 'none',
        '&:hover': {
          border: '0.78px solid #03813E',
        },
      }),
      singleValue: (base) => ({
        ...base,
        color: '#000', // Change the selected value color
      }),
      placeholder: (base) => ({
        ...base,
        color: '#575252', // Change the placeholder color
      }),
      dropdownIndicator: (base) => ({
        ...base,
        color: '#fff', // Change the dropdown arrow color
        '&:hover': {
          color: '#fff', // Change the arrow color on hover
        },
      }),
      indicatorSeparator: () => ({
        display: 'none', // Remove the vertical separator line
      }),
    };
  
  
    
  useEffect(() => {
    setError(null);
    const fetchCourse = async () => {
      try {
        // const response = await axiosClient.get(`user/ideas-programme/countries/${countryId}/states`);
        const response = await axiosClient.get('user/ideas-programme/all_digital_skills_track');
        // console.log('response is course', response.data.data)
        const options = response.data.data;
        setCourseOptions(options);
      } catch (err) {
        setError({ ...error, fetch: "Error fetching courses" });
        // console.error("Error fetching courses:", err);
      }
    };
    fetchCourse();
  }, []);
  
  const handleStateChange = (selectedOption) => {
    setCourse(selectedOption);
};

const handleSubmit = async () => { 
  try {
    setLoading(true);
    
    const data = {
      email: application.email,
      digital_skills_track_id: course?.value,
    };
    
    const response = await axiosClient.post(
      `admin/ideas-programme/enroll-track`, 
      data, 
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setMessage(response.data.message)
    console.log('response is ', response);

  } catch (error) {
    // setError(error.response.data.error)

    console.log('error response', error);
  } finally {
    setLoading(false); // Optional: reset loading state
  }
};


console.log('message is ', message)

// console.log('courses are ', course)

      // console.log('error is ', error)
const handleApproveClick = () => {
  setActionType('approve');
  console.log('approve')
  SetConfirmPopup(true);
};

const handleDeclineClick = () => {
  setActionType('decline');
  SetConfirmPopup(true);
};


useEffect(() => {
  if (error) {
    setSuccessPopup(true);
  }
}, [error]);

useEffect(() => {
  if (message) {
      setSuccessPopup(true);
  }
}, [message]);



      const handlePopup = () => SetConfirmPopup(true)
      const handleClose = () => SetConfirmPopup(false)
      const handleSuccessPopup = () => setSuccessPopup(false)

      const payload = {
            application_id: application?.id
      };
      // console.log('id is ', payload)


      const trackPayload = {
        
          email: application?.email,
          digital_skills_track_id: course?.value,
          current_digital_skills_track_id: application?.digital_skills_track_id,
          application_id: application?.id 
      }
console.log('payload is ', trackPayload);

      const handleApprove = async () => {
            setLoading(true); 
            setMessage(null);
            setError(null);
            try {
              const response = await axiosClient.post('/admin/ideas-programme/approve', payload, {
                headers: {
                  Authorization: `Bearer ${token}`, 
                  'Content-Type': 'application/json',
                },
              });
              // console.log('response is ', response.data)
              setMessage(response.data.message);
              SetConfirmPopup(false);
              // console.log('Approve response:', response);
            } catch (err) {
              setError(err.response.data.error)
              
              // console.error('Error approving the idea ..........:', err.response.data.error);
            } finally {
              setLoading(false); 
            }
          };


        
      
        const handleTrackChange = async () => {
          setLoading(true);
          setMessage(null);
          setError(null);
        
          try {
            const response = await axiosClient.post(
              `/admin/ideas-programme/change-user-track`,
              trackPayload,
              {
             
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            );
        
            console.log('response is ', response);
    dispatch(getApplication());

            setMessage(response.data.message);
            SetConfirmPopup(false);
            // console.log('Approve response:', response);
          } catch (err) {
            setError(err.response.data.message || err.response.data.error)
            console.error('Error approving the idea ..........:', err.response.data);
          } finally {
            setLoading(false);
          }
        };
        


      const handleDecline = async () => {
            setLoading(true);
            setMessage(null);
            setError(null); 
            try {
              const response = await axiosClient.post('/admin/ideas-programme/decline', payload, {
                headers: {
                  Authorization: `Bearer ${token}`, 
                  'Content-Type': 'application/json',
                },
              });
              setMessage(response.data.data.message);
              SetConfirmPopup(false);
              console.log('Approve response:', response);
            } catch (err) {
              setError(err.response.data.error);
              console.error('Error approving the idea:', err.response.data.error);
            } finally {
              setLoading(false); 
            }
          };

   
    
      // console.log('application is ', application)
      if (!application) {
        return <div>Application not found</div>;
      }

      if (loading) {
        return (
    
          <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-40'>
          <div className='bg-transparent p-8 gap-4 rounded-xl w-full mx-4 hd-[250px] md:w-[400px] h-[300px] flex flex-col items-center justify-center'>
    
            <div className="loader" />
    
    
                
                </div>
                </div>
        )
      }
  
  const openImageModal = (imageUrl) => {
    setFullImageUrl(imageUrl);
    setRotation(0);
    setIsModalOpen(true);
  };

  const rotateLeft = () => {
    setRotation((prevRotation) => prevRotation - 90);
  };

  const rotateRight = () => {
    setRotation((prevRotation) => prevRotation + 90);
  };

  return (
    <div className="w-full mt-28">
        {/* <div className="w-full flex justify-center items-center py-5">
            <img src={application.national_id_img_url} className="w-20 h-20 rounded-full" alt="Applicant Id" />
        </div> */}
        <div className="w-full flex justify-center items-center py-5">
          <img
            src={application.national_id_img_url}
            className="w-20 h-20 rounded-full cursor-pointer"
            alt="Applicant Id"
            onClick={() => openImageModal(application.national_id_img_url)}
          />
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-4 rounded relative flex items-center">
              <div className="absolute top-2 left-2 flex space-x-2">
                <button 
                  onClick={rotateLeft} 
                  className="bg-blue-500 text-white font-bold py-1 px-2 rounded hover:bg-blue-700 transition z-10"
                >
                  Rotate Left
                </button>
                <button 
                  onClick={rotateRight} 
                  className="bg-blue-500 text-white font-bold py-1 px-2 rounded hover:bg-blue-700 transition z-10"
                >
                  Rotate Right
                </button>
              </div>
              <button onClick={() => setIsModalOpen(false)} className="absolute top-2 right-2 bg-red-600 text-white font-bold py-1 px-3 rounded hover:bg-red-700 transition z-10">
                Close
              </button>
              <img 
                src={fullImageUrl} 
                className="max-w-full max-h-[80vh] transform" 
                alt="Full Applicant Id" 
                style={{ transform: `rotate(${rotation}deg)` }}
              />
            </div>
          </div>
        )}
      
      <div className="grid grid-cols-2 gap-y-5 w-full px-[140px]">
  <div className="flex gap-3">
    <p className="font-semibold text-sm">First Name</p>
    <span className="text-[#737373]">{application.first_name}</span>
  </div>
  <div className="flex gap-3">
    <p className="font-semibold text-sm">Last Name</p>
    <span className="text-[#737373]">{application.last_name}</span>
  </div>

  <div className="flex gap-3">
    <p className="font-semibold text-sm">Date of Birth</p>
    <span className="text-[#737373]">{application.date_of_birth}</span>
  </div>
  <div className="flex gap-3">
    <p className="font-semibold text-sm">Marital Status</p>
    <span className="text-[#737373]">{application.marital_status}</span>
  </div>

  <div className="flex gap-3">
    <p className="font-semibold text-sm">Gender</p>
    <span className="text-[#737373]">{application.gender}</span>
  </div>
  <div className="flex gap-3">
    <p className="font-semibold text-sm">Highest Qualification</p>
    <span className="text-[#737373]">{application.highest_qualification}</span>
  </div>

  <div className="flex gap-3">
    <p className="font-semibold text-sm">Email Address</p>
    <span className="text-[#737373]">{application.email}</span>
  </div>
  <div className="flex gap-3">
    <p className="font-semibold text-sm">Confirm Email Address</p>
    <span className="text-[#737373]">{application.email}</span>
  </div>

  <div className="flex gap-3">
    <p className="font-semibold text-sm">Phone Number</p>
    <span className="text-[#737373]">{application.phone_number}</span>
  </div>
  <div className="flex gap-3">
    <p className="font-semibold text-sm">Confirm Phone Number</p>
    <span className="text-[#737373]">{application.phone_number}</span>
  </div>

  <div className="flex gap-3">
               <p className="font-semibold text-sm">LinkedIn </p><span className="text-[#737373]">{application.linkedin_id || "-"}</span> 

  </div>
  <div className="flex gap-3">
  <p className="font-semibold text-sm">Facebook  </p> <span className="text-[#737373]">{application.facebook_id || "-"}</span> 

  </div>

  <div className="flex gap-3">
    <p className="font-semibold text-sm">Twitter</p>
    <span className="text-[#737373]">{application.x_id}</span>
  </div>
  <div className="flex gap-3">
  <p className="font-semibold text-sm">Emergency Number </p> <span className="text-[#737373]">{application.emergency_contact}</span> 
 
  </div>
  <div className="flex gap-3">
    <p className="font-semibold text-sm">NIN</p>
    <span className="text-[#737373]">{application.government_id}</span>
  </div>
  <div className="flex gap-3">
  <p className="font-semibold text-sm">Guardian Name</p><span className="text-[#737373]">{application.guardian_name}</span> 
   
  </div>

  <div className="flex gap-3">
    <p className="font-semibold text-sm">State of Residence</p>
    <span className="text-[#737373]">{application.state_of_residence}</span>
  </div>
  <div className="flex gap-3">
    <p className="font-semibold text-sm">LGA of Residence</p>
    <span className="text-[#737373]">{application.lga_of_residence}</span>
  </div>

  <div className="flex gap-3">

    <p className="font-semibold text-sm">Has Disability </p>
    <span className="text-[#737373]">{application.has_disability === 1 ? "Yes" : "No"}</span> 

  </div>
  {
                  application.disability_type && (
                    <div className="flex gap-3">

                        <p className="font-semibold text-sm">Has Computer for training</p> <span className="text-[#737373]">{application.disability_type}</span> 
            
                        </div>
                  )
            } 
  <div className="flex gap-3">
  <p className="font-semibold text-sm">Can you use Zoom/Google meet?</p><span className="text-[#737373]">{application.can_use_zoom_teams_meet === 1 ? "Yes" : "No"}</span> 
   
  </div>
  <div className="flex gap-3">
    <p className="font-semibold text-sm">Town/Locality</p>
    <span className="text-[#737373]">{application.town_locality}</span>
  </div>
  <div className="flex gap-3">
    <p className="font-semibold text-sm">Employment Status</p>
    <span className="text-[#737373]">{application.employment_status}</span>
  </div>
  <div className="flex gap-3">
    <p className="font-semibold text-sm">Age Group</p>
    <span className="text-[#737373]">{application.age_group}</span>
  </div>

  <div className="flex gap-3">
  <p className="font-semibold text-sm">How did you here about us? </p><span className="text-[#737373]">{application.how_did_you_hear}</span> 
 
  </div>
  <div className="flex gap-3">
  <p className="font-semibold text-sm">Others for how you hear about us?</p><span className="text-[#737373]">{application.other || '-'}</span> 

  </div>
  <div className="flex gap-3">
    <p className="font-semibold text-sm">Can transport to Mabushi?</p>
    <span className="text-[#737373]">{application.commute_affordability}</span>
  </div>
  <div className="flex gap-3">
    <p className="font-semibold text-sm">Aim of Training</p>
    <span className="text-[#737373]">{application.aim_of_training.join(', ')}</span>
  </div>
  <div className="flex gap-3">
	  <p className="font-semibold text-sm">DIGITAL SKILLS TRACK</p>
	  <span className="text-[#737373]">
		{application.digital_skills_track_id ? application.digital_skills_track?.name : 'Null'}
	  </span>
	</div>
</div>
{applicationTrack && (
  canManageApplication && (
    <div className="flex items-center justify-center w-full flex-col gap-2 pt-5">
      <h1 className="font-semibold text-base">Change Track</h1>

      <div className="flex items-center gap-5">
        <br />
        <Select
          styles={customStyles}
          onChange={handleStateChange}
          options={courseOptions.map((course) => ({
            label: course.name,
            value: course.id,
          }))}
          placeholder="Click here to Select a Track"
          className="rounded-md"
          required
        />
        <button
          onClick={handleTrackChange}
          className="bg-[#03813E] text-white text-sm py-2 px-5 border-2 border-solid border-[#03813E] rounded-lg transition-colors duration-500 cursor-pointer hover:bg-white hover:text-[#03813E] hover:border-[#03813E]"
        >
          Change DigitalTrack of {application.first_name} {application.last_name}
        </button>
      </div>
    </div>
  )
)}

<div className="w-full flex justify-center items-center gap-10 py-5">
  {acceptedApplication === "Rejected" ? (
    <span className="bg-red-400 py-1 px-2 rounded-md text-white">Application Declined</span>
  ) : acceptedApplication === "Accepted" ? (
    <div className="flex items-center flex-col gap-4">
      <span className="bg-green-400 py-1 px-2 rounded-md text-white">Application Approved</span>
    </div>
  ) : (
    <>
      {canManageApplication && (
        <>
          {canAssignTrack ? (
            <div className="flex items-center gap-5">
              <br />
              <Select
                styles={customStyles}
                onChange={handleStateChange}
                options={courseOptions.map((course) => ({
                  label: course.name,
                  value: course.id,
                }))}
                placeholder="Click here to Select a Track"
                className="rounded-md"
                required
              />
              <button
                onClick={handleSubmit}
                className="bg-[#03813E] text-white text-sm py-2 px-5 border-2 border-solid border-[#03813E] rounded-lg transition-colors duration-500 cursor-pointer hover:bg-white hover:text-[#03813E] hover:border-[#03813E]"
              >
                Assign DigitalTrack to {application.first_name} {application.last_name}
              </button>
            </div>
          ) : (
            ''
          )}
          <button
            onClick={() => {
              handlePopup();
              handleApproveClick();
            }}
            className="bg-[#03813E] text-white text-sm py-2 px-5 border-2 border-solid border-[#03813E] rounded-lg transition-colors duration-500 hover:bg-white hover:text-[#03813E] hover:border-[#03813E]"
          >
            Approve Application
          </button>
          <button
            onClick={() => {
              handlePopup();
              handleDeclineClick(); // Call the function to handle rejection
            }}
            className={`border-2 border-solid border-[#03813E] text-[#03813E] py-2 px-5 text-sm rounded-lg transition-colors duration-500 hover:bg-[#03813E] hover:text-white ${
              acceptedApplication === "Accepted" ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={acceptedApplication === "Accepted"}
          >
            Decline Application
          </button>
        </>
      )}
    </>
  )}
</div>

{/* Confirm Popup */}
<ConfirmPopup
  isVisible={popup}
  onConfirm={actionType === 'approve' ? handleApprove : handleDecline}
  title={`Dear ${user?.first_name},`}
  message={`Are you sure you want to ${actionType === 'approve' ? 'approve' : 'decline'} ${application.first_name} ${application.last_name}'s application?`}
  onCancel={handleClose}
/>

{/* Success Alert */}
<SuccessAlert
  isVisible={successPopup}
  error={error}
  success={message}
  onClose={handleSuccessPopup}
/>
</div>
);
};
export default ApplicationDetail;
