
import { useDispatch } from 'react-redux';
import MainDash from '../../components/MainDash';

const Dashboard = () => {
  

  return (
    <div >
      <MainDash
       
      />
    </div>
  )
}

export default Dashboard