import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { useEffect } from "react";
import { logout } from "../Controllers/authSlice";



const LogoutPopup = ({ isVisible, onClose, closeTimes }) => {

 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector(state => state.auth); // Assuming `isAuthenticated` is in your auth state

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  const handleLogout = async () => {
    try {
      await dispatch(logout()).unwrap(); // unwrap to get the resolved value
      navigate('/login');
      console.log('Logout successful');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };
  
  if (!isVisible) return null;


 


  return (
    <div className='fixed inset-0 flex items-center justify-center xl:ml-[320px] xl:mt-[200px] xl:mr-[36px] bg-black bg-opacity-80 z-40'>
      <div className='bg-white px-5 h-[250px] py-10  rounded-xl w-full mx-4  md:w-[400px]  min-h-fit flex flex-col justify-between '>
       
       

       

<div className="flex flex-col gap-6 justify-between items-center w-full px-[100px]">
    <h1 className="text-[#121010] font-semibold text-lg">Logout!</h1>

    <p className="text-base leading-[16px]  text-center">Are you sure that you want to logout?</p>

    
    </div>        
        
       <div className='flex items-center justify-between w-full '>
        <button onClick={handleLogout}  className='h-[46px] w-auto px-6 text-sm bg-[#03813E] flex items-center justify-center font-bold rounded-xl  text-white shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300'>Confirm Logout</button>
        <button
          className='h-[46px] w-[120px]  rounded-xl  text-[#03813E] border-[1px] border-solid border-[#03813E] shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300 font-bold'
          onClick={onClose}
        >
          Cancel
        </button>
       </div>
     
      </div>
    </div>
  );
};

export default LogoutPopup;
