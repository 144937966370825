const ConfirmPopup = ({ isVisible, message, onConfirm, onCancel, title }) => {
    if (!isVisible) return null;

    return (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-40'>
        <div className='bg-white px-5  gap-4 rounded-xl w-full mx-4 md:max-w-[400px] h-[250px] flex flex-col items-center justify-center'>
<div className="flex flex-col justify-center items-center  w-full  ">
    <p className="text-lg italic">{title}</p>
<p className="text-lg mb-4 text-center">{message}</p>
</div>                <div className="flex justify-between w-full gap-4">
                    <button
                        onClick={onCancel}
                        className="bg-gray-200 px-4 py-2 rounded-md hover:bg-gray-300 transition"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={()=>{
                            onConfirm()
                            onCancel()
                        }}
                        className="bg-[#03813E] text-white px-4 py-2 rounded-md hover:bg-[#03813E] hover:opacity-[.9] transition"
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmPopup;
