import  { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import NavBar from './components/NavBar'
import Footer from './components/Footer'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { HelmetProvider } from 'react-helmet-async';



const App = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS on component mount
  }, []);

  return (
    <HelmetProvider>

    <div className='w-full'>
<NavBar/>




<Outlet/>



<Footer/>
    </div>

    </HelmetProvider>

  )
}

export default App