import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getApplication, setSearch } from '../Controllers/authSlice';
import axiosClient from '../axiosClient';
import ConfirmPopup from './ConfirmPopup';
import SuccessAlert from './SuccessAlert';

const statuses = {
  Accepted: 'bg-green-100 text-green-700',
  Pending: 'bg-yellow-100 text-yellow-700',
  Rejected: 'bg-red-100 text-red-700',
};

const ApplicationContent = () => {
  const dispatch = useDispatch();
  const { applications = [], search, user, page, page_size, totalCount, token } = useSelector((state) => state.auth); 
  const [activeTab, setActiveTab] = useState('ALL');
  const isAdmin = user?.roles?.some(role => role.name === "Super Admin");
  const [currentPage, setCurrentPage] = useState(1); 
  const [loading, setLoading] = useState(false);
  const [pageSize] = useState(20);
  const navigate = useNavigate();
  const statusOrder = ['Accepted', 'Pending', 'Rejected'];
  const [lastExportTime, setLastExportTime] = useState(0);
  const [showExportWarning, setShowExportWarning] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [currentApplication, setCurrentApplication] = useState(null);
  const [successPopup, setSuccessPopup] = useState(false);
  const handleSuccessPopup = () => setSuccessPopup(false)


  const EXPORT_COOLDOWN = 600000;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fullImageUrl, setFullImageUrl] = useState('');
  const [confirmScreened, setConfirmedScreened] = useState('');
  const [confirmEject, setConfirmedEject] = useState('');
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    dispatch(getApplication({ page: currentPage, page_size: pageSize, search }));
  }, [dispatch, currentPage, pageSize, search]);

  const filterApplications = (status) => {
    if (!Array.isArray(applications)) return [];
    let filteredApps = status === 'ALL' ? applications : applications.filter((app) => app.application_status === status);
    if (status !== 'ALL') {
      filteredApps.sort((a, b) => statusOrder.indexOf(a.application_status) - statusOrder.indexOf(b.application_status));
    }
    return filteredApps;
  };

  const handleViewClick = (id) => navigate(`/dashboard/application/${id}`);

  const handleSearchChange = (e) => dispatch(setSearch(e.target.value));

  const handlePaginationChange = (pageNum) => setCurrentPage(pageNum);
  // const screened = applications?.screen_status;

  const totalPages = Math.ceil(totalCount / pageSize);

  const openImageModal = (imageUrl) => {
    setFullImageUrl(imageUrl);
    setRotation(0);
    setIsModalOpen(true);
  };



  console.log('application....', applications);

  const rotateLeft = () => setRotation((prevRotation) => prevRotation - 90);

  const rotateRight = () => setRotation((prevRotation) => prevRotation + 90);

  const handleClose = () => setConfirmPopup(false);

  const handleConfirmPopup = (application) => {
    setCurrentApplication(application); 
    setConfirmPopup(true); 
  };


  useEffect(() => {
    if (message) {
        setSuccessPopup(true);
    }
  }, [message]);
  

  const downloadExcel = async (exportParam) => {
    setLoading(true);
    try {
      const response = await axiosClient.get(`/admin/ideas-programme/applications`, {
        params: exportParam,
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'ideas-applications.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the Excel file', error);
    } finally {
      setLoading(false);
    }
  };

  const handleScreenedMessage = ()=> setConfirmedScreened('screen');
  const handleEjetMessage = ()=> setConfirmedEject('eject');

  const handleEject = async (application) => {
    setLoading(true);
    setMessage(null);
    setError(null);
    try {
      const response = await axiosClient.post(
        '/admin/ideas-programme/eject-user-from-track',
        {
          email: application.email,
          application_id: application.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setMessage(response.data.message);
      console.log('Eject response:', response.data);
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
      console.error('Error ejecting the idea:', err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const payload = {
    application_id: applications?.id
};
console.log('payload is ', payload);
  const handleScreen = async (application) => {
    setLoading(true); 
    setMessage(null);
    setError(null);
    try {
      const response = await axiosClient.post('/admin/ideas-programme/screen', {
        application_id: application?.id
      }, {
        headers: {
          Authorization: `Bearer ${token}`, 
          'Content-Type': 'application/json',
        },
      });
      // console.log('response is ', response.data)
      setMessage(response.data.message);
      // SetConfirmPopup(false);
      // console.log('Approve response:', response);
    } catch (err) {
      setError(err.response.data.error)
      
      console.error('Error approving the idea ..........:', err.response);
    } finally {
      setLoading(false); 
    }
  };

  const handleCurrentPageDownload = () => {
    const exportParam = {
      search,
      page_size: 20,
      page: currentPage,
      status: activeTab === 'ALL' ? undefined : activeTab,
      export: 'excel',
    }; 
    downloadExcel(exportParam);
  };

  const canExport = () => {
    const currentTime = Date.now();
    const lastExportTime = parseInt(localStorage.getItem('lastExportTime') || '0', 10);
    return currentTime - lastExportTime >= EXPORT_COOLDOWN;
  };

  const handleAllDownload = () => {
    if (canExport()) {
      const exportParam = {
        search,
        page_size: totalCount,
        status: activeTab === 'ALL' ? undefined : activeTab,
        export: 'excel',
      };
      downloadExcel(exportParam);
      localStorage.setItem('lastExportTime', Date.now().toString());
    } else {
      setShowExportWarning(true);
      setTimeout(() => setShowExportWarning(false), 3000);
    }
  };

  const getRemainingTime = () => {
    const currentTime = Date.now();
    const timeLeft = EXPORT_COOLDOWN - (currentTime - lastExportTime);
    return Math.max(0, Math.ceil(timeLeft / 60000));
  };

  const handleWarning = () => {
    setShowExportWarning(true);
    setTimeout(() => setShowExportWarning(false), 3000);
  };

  if (loading) {
    return (
      <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-40'>
        <div className='bg-transparent p-8 gap-4 rounded-xl w-full mx-4 hd-[250px] md:w-[400px] h-[300px] flex flex-col items-center justify-center'>
          <div className="loader" />
        </div>
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="flex justify-between items-center w-full">
        <div className='flex space-x-4 mb-4'>
          {['ALL', 'Accepted', 'Screened', 'Pending', 'Rejected'].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`py-2 px-4 ${activeTab === tab ? 'border-b-4 border-blue-500 text-blue-500' : 'text-gray-600'}`}
            >
              {tab}
            </button>
          ))}
        </div>
        {isAdmin && (
          <div className='flex items-center gap-2'>
            <button
              onClick={handleCurrentPageDownload}
              className="bg-[#03813E] text-white text-sm py-2 px-5 border-2 border-solid border-[#03813E] rounded-lg transition-colors duration-500 cursor-pointer hover:bg-white hover:text-[#03813E] hover:border-[#03813E]"
            >
              Export Current page
            </button>
            <button
              title={`Please wait for ${getRemainingTime()} minutes before retrying.`}
              onClick={canExport() ? handleAllDownload : handleWarning}
              className={`bg-[#03813E] text-white text-sm py-2 px-5 border-2 border-solid border-[#03813E] rounded-lg transition-colors duration-500 cursor-pointer hover:bg-white hover:text-[#03813E] hover:border-[#03813E] ${!canExport() ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={!canExport()}
            >
              Export All
            </button>
          </div>
        )}
      </div>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search applications..."
          value={search}
          onChange={handleSearchChange}
          className="p-2 border border-gray-300 rounded"
        />
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="p-2 border border-gray-300">Image</th>
              <th className="p-2 border border-gray-300">Applicant Name</th>
              <th className="p-2 border border-gray-300">Email Address</th>
              <th className="p-2 border border-gray-300">Phone No.</th>
              <th className="p-2 border border-gray-300">Highest Qualifications</th>
              <th className="p-2 border border-gray-300">Sex</th>
              <th className="p-2 border border-gray-300">Status</th>
              <th className="p-2 border border-gray-300">Action</th>
              {/* <th className="p-2 border border-gray-300">Action2</th> */}
              {isAdmin && <th className="p-2 border border-gray-300">Action2</th>}
            </tr>
          </thead>
          <tbody>
            {filterApplications(activeTab).map((application) => (
              <tr key={application.id} className="text-center">
                <td className="p-2 border border-gray-300">
                  <img
                   src={application.national_id_img_url} 
                    alt="applicant"
                    className="w-8 h-8 object-cover rounded-full cursor-pointer"
                    onClick={() => openImageModal(application.image_url)}
                  />
                </td>
                <td className="p-2 border border-gray-300">{application.first_name} {application.last_name}</td>
                <td className="p-2 border border-gray-300">{application.email}</td>
                <td className="p-2 border border-gray-300">{application.phone}</td>
                <td className="p-2 border border-gray-300">{application.highest_qualification}</td>
                <td className="p-2 border border-gray-300">{application.sex}</td>
                <td className={`p-2 border border-gray-300 ${statuses[application.application_status]}`}>
                  {application.application_status}
                </td>
                <td className="p-2 border border-gray-300 text-center">
                  <button onClick={() => handleViewClick(application.id)}>View</button>
                </td>
                {/* <td className="p-2 border border-gray-300 text-center"> */}

                {/* <button onClick={() => handleScreen(application)}>Screen</button> */}
                  {/* {
                    screened ? ( <button onClick={() => handleScreen()}>Screen</button>) : ''
                  } */}
                
                {/* </td> */}
                {isAdmin && application.application_status === 'Accepted' && (
  <td className="p-2 border border-gray-300 flex items-center gap-2">
    <button
      onClick={() =>
      {
        handleConfirmPopup(application);
        handleEjetMessage();
      }
        }
      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
    >
      Eject
    </button>
{/* {
  application.application_status !== 'Screened' ? ( */}
     <button 
    className="bg-[#03813E] text-white text-sm py-1 px-2 font-bold border-2 border-solid border-[#03813E] rounded-lg transition-colors duration-500 cursor-pointer hover:bg-white hover:text-[#03813E] hover:border-[#03813E]"
  
  onClick={() => {
  handleScreenedMessage();
  handleConfirmPopup(application);
  }}>Screen</button>
  
  {/* ) : 'Screened'
} */}

  </td>
)}

              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4 items-center">
  {/* Previous button */}
  <button
    onClick={() => handlePaginationChange(currentPage - 1)}
    disabled={currentPage === 1} // Disable if on the first page
    className="px-4 py-2 m-1 border bg-white text-blue-500 disabled:opacity-50"
  >
    Prev
  </button>

  {/* Current Page of Total Pages */}
  <span className="px-4 py-2 m-1">
    {currentPage} of {totalPages}
  </span>

  {/* Next button */}
  <button
    onClick={() => handlePaginationChange(currentPage + 1)}
    disabled={currentPage === totalPages} // Disable if on the last page
    className="px-4 py-2 m-1 border bg-white text-blue-500 disabled:opacity-50"
  >
    Next
  </button>
</div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
          <div className="relative">
            <img
              src={fullImageUrl}
              alt="Full Size"
              className="max-w-full max-h-full object-contain"
              style={{ transform: `rotate(${rotation}deg)` }}
            />
            <button onClick={rotateLeft} className="absolute top-2 left-2 bg-white p-1 rounded">
              ↺
            </button>
            <button onClick={rotateRight} className="absolute top-2 right-2 bg-white p-1 rounded">
              ↻
            </button>
            <button onClick={() => setIsModalOpen(false)} className="absolute top-2 right-2 bg-white p-1 rounded">
              Close
            </button>
          </div>
        </div>
      )}
      {/* {confirmPopup && ( */}
      <ConfirmPopup
  isVisible={confirmPopup}
  onConfirm={() => {
    handleConfirmPopup(false);
    if (confirmScreened) {
      handleScreen(currentApplication);
    } else {
      handleEject(currentApplication);
    }
  }}
  onCancel={handleClose}
  message={
    `${confirmScreened
      ? 'Are you sure this application has been screened properly?'
      : 'Are you sure you want to eject this applicant? Kindly note the action cannot be reversed!'}`}
/>

     
     <SuccessAlert
  isVisible={successPopup}
  error={error}
  success={message}
  onClose={handleSuccessPopup}
  path='/dashboard/application'
/>
    </div>
  );
};

export default ApplicationContent;
