import React from 'react'
import ApplicationContent from '../../components/ApplicationContent'

const Application = () => {
  return (
    <div className='mt-[100px] w-full'>
    

    <ApplicationContent />
    </div>
  )
}

export default Application
