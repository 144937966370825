import  { useEffect, useState } from 'react';
import MiddleDash from './MiddleDash';
import PieChart from './PieChart';
import Button from './Button';

const MainDash = ({   }) => {
 


  return (
    <div>


<div className='bg-red-600 w-full'>
<Button text="Close Application" />

</div>

<MiddleDash/>

       
       <PieChart />
      
    
    </div>
  );
};

export default MainDash;
