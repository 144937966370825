import ApplicationDetail from "../../components/ApplicationDetail"

const ApplicationDetailPage = () => {
  return (
    <div>

        <ApplicationDetail />
      
    </div>
  )
}

export default ApplicationDetailPage
