// contexts/context.js
import React, { createContext, useReducer } from 'react';

const initialState = {
  toggleNavbar: false,
  toggleSidebar: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_TOGGLE_NAVBAR':
      return {
        ...state,
        toggleNavbar: action.payload,
      };
    case 'SET_TOGGLE_SIDEBAR':
      return {
        ...state,
        toggleSidebar: action.payload,
      };
    default:
      return state;
  }
};

export const Context = createContext();

export const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Context.Provider value={{ state, dispatch }}>
      {children}
    </Context.Provider>
  );
};
