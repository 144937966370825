import { useState } from 'react';
import axiosClient from '../axiosClient';
import { FirstFormPage } from './FirstFormPage';
import { SecondFormPage } from './SecondFormPage';
import { previous } from '../assets';
import NotificationMessage from '../components/NotificationMessage';
import { ThirdFormPage } from './ThirdFormPage';
import { Helmet } from 'react-helmet-async';
import SEO from '../SEO';

const MultiStepForm = () => {
  const [step, setStep] = useState(1);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
    marital_status: "",
    email: "",
    confirm_email: "",
    phone_number: "",
    confirm_phone_number: "",
    linkedin_id: "",
    facebook_id: "",
    x_id: "",
    highest_qualification: "",
    government_id: "",
    state_of_residence: "",
    lga_of_residence: "",
    town_locality: "",
    employment_status: "",
    has_disability: "",
    disability_type: null,
    computer_literacy: "",
    can_use_zoom_teams_meet: "",
    has_computer_for_training: "",
    available_for_six_months: null,
    digital_skills_track_id: "",
    how_did_you_hear: "",
    other_source: "",
    aim_of_training: [], 
    national_id_img: null, 
    monthly_income: null,
    commute_affordability: "",
    number_of_certificates: "",
    age_group: "",
    is_free_from_sickness: null,
    guardian_name: "",
    emergency_contact: "",
  });

  console.log(formData)

  const requiredFields = {
    1: ['first_name', 'last_name', 'email', 'date_of_birth', 'gender', 'marital_status', 'highest_qualification', 'phone_number', 'lga_of_residence', 'lga_of_residence', 'state_of_residence'], // Add required fields for step 1
    2: ['age_group', 'town_locality', 'computer_literacy', 'monthly_income', 'employment_status', 'aim_of_training'], 
    3: ['available_for_six_months'], 
   
  };

  // const nextStep = () => {
  //   const fieldsToCheck = requiredFields[step];
  //   const missingFields = fieldsToCheck.filter(field => !formData[field]);

  //   if (missingFields.length > 0) {
  //     setError('Please fill all the required fields to continue');
  //     return; // Prevent moving to the next step
  //   }

  //   setError(null); // Clear error
  //   setStep(step + 1);
  // };

  const hasDisability = formData.disability_type === 1; 

  const nextStep = () => {
    const fieldsToCheck = requiredFields[step];
    const missingFields = fieldsToCheck.filter(field => !formData[field]);
    // console.log('missing field is before', missingFields)
  
    // Check if disability type is yes and add has_disability to missingFields if it's not filled
    if (step === 2 && hasDisability && !formData.has_disability) {
      missingFields.push('has_disability');
    }

    // console.log('missing field is after', missingFields)
  
    if (missingFields.length > 0) {
      setError('Please fill all the required fields to continue');
      return; // Prevent moving to the next step
    }
  
    setError(null); // Clear error
    setStep(step + 1);
  };
  



  const prevStep = () => setStep(step - 1);

  const handleChange = (name, value) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setError([]);
  //   setLoading(true);
  //   try {
  //     const response = await axiosClient.post('user/ideas-programme/apply', formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //         'Accept': 'application/json',
  //       },
  //     });
  //     setSuccess(response.data.message);
  //     setError(null);
  //   } catch (err) {
  //     setError(err.response.data);
  //     setSuccess(null);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError([]);
    setLoading(true);
  
    // Create a FormData object
    const submissionData = new FormData();
  
    // Loop through formData and append to FormData
    Object.keys(formData).forEach((key) => {
      // Check if the field is an array (e.g., aim_of_training)
      if (Array.isArray(formData[key])) {
        formData[key].forEach((value) => {
          submissionData.append(`${key}[]`, value); // Append each item in the array
        });
      } else {
        submissionData.append(key, formData[key]); // For non-array fields
      }
    });
  
    try {
      const response = await axiosClient.post('user/ideas-programme/apply', submissionData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Required for handling file uploads
          'Accept': 'application/json',
        },
      });
      setSuccess(response.data.message);
      setError(null);
    } catch (err) {
      console.log(err);
      setError(err.response.data);
      setSuccess(null);
    } finally {
      setLoading(false);
    }
  };
  

  const handleSubmitg = async (e) => {
    e.preventDefault();
    setError([]);
    setLoading(true);
  
    // Create a FormData object
    const submissionData = new FormData();
    
    // Append each field from formData to FormData
    Object.keys(formData).forEach((key) => {
      submissionData.append(key, formData[key]);
    });
  
    try {
      const response = await axiosClient.post('user/ideas-programme/apply', submissionData, {
        headers: {
          'Content-Type': 'multipart/form-data', // This header is required to handle file uploads
          'Accept': 'application/json',
        },
      });
      setSuccess(response.data.message);
      setError(null);
    } catch (err) {
      console.log(err)
      setError(err.response.data);
      setSuccess(null);
    } finally {
      setLoading(false);
    }
  };
  
  const formatErrorMessages = (error) => {
    if (!error) return "An unknown error occurred.";
    const messages = [];

    if (error.message) {
      messages.push(error.message);
    }

    if (error.errors) {
      Object.keys(error.errors).forEach(field => {
        messages.push(`${field}: ${error.errors[field].join(', ')}`);
      });
    }

    return messages.join(' | ');
  };

  const notResidentOfAbuja = formData.state_of_residence === 'No'; // Assuming 'abuja' is the value for Abuja

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return <FirstFormPage handleChange={handleChange} formData={formData} />;
      case 2:
        return <SecondFormPage handleChange={handleChange} formData={formData} />;
      case 3:
        return <ThirdFormPage handleChange={handleChange} formData={formData} />;
      default:
        return <FirstFormPage handleChange={handleChange} formData={formData} />;
    }
  };

  return (
<>
<SEO
title="Apply for world bank training programme"
description="World Bank training programme for Nigerian innovative Youths."
name="Ideas programme"

/>
   
    <div className="formbg md:my-[100px] py-[100px] w-full h-auto">
      <div className="w-full px-5 lg:px-[100px]">
        <h1 className="text-lg font-semibold flex items-center text-white justify-center">Fill in Your Details</h1>
        {(success || error) && (
          <NotificationMessage 
            message={success ? success : formatErrorMessages(error) || error} 
            title={success ? "Congratulations!!!" : "Oops!"} 
            isSuccess={!!success} 
          />
        )}
        <form onSubmit={handleSubmit}>
          {renderStepContent()}
          
          <div className="flex justify-between mt-5">
            {step > 1 && (
              <button onClick={prevStep} type="button" className="flex items-center">
                <img src={previous} alt="Previous" />
              </button>
            )}
            {/* {step < 3 && !notResidentOfAbuja ? (
              <button
                onClick={nextStep}
                type="button"
                className="h-[50px] py-[18.5px] px-[40px] flex items-center justify-center text-white bg-[#03813E] rounded-md hover:opacity-[0.8] transition-all duration-300"
              >
                Next
              </button>
            ) : 
            
            
            (
              <button
                type="submit"
                className={`py-[18.5px] px-[60px] flex items-center justify-center text-white bg-[#03813E] rounded-md hover:opacity-[0.8] transition-all duration-300 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={loading}
              >
                {loading ? 'Submitting...' : 'Submit'}
              </button>
            )} */}

{step < 3 ? (
  !notResidentOfAbuja ? (
    <button
      onClick={nextStep}
      type="button"
      className="h-[50px] py-[18.5px] px-[40px] flex items-center justify-center text-white bg-[#03813E] rounded-md hover:opacity-[0.8] transition-all duration-300"
    >
      Next
    </button>
  ) : (
    <NotificationMessage 
      message='You are not eligible as you are not from Abuja.' 
      title="Oops!"
      isSuccess={!!success} 
    />
  )
) : (
  <button
    type="submit"
    className={`py-[18.5px] px-[60px] flex items-center justify-center text-white bg-[#03813E] rounded-md hover:opacity-[0.8] transition-all duration-300 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
    disabled={loading}
  >
    {loading ? 'Submitting...' : 'Submit'}
  </button>
)}

          </div>
        </form>
      </div>
    </div>
   
</>

  );
};

export default MultiStepForm;
