import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, user, token } = useSelector((state) => state.auth);
  const location = useLocation();

  console.log('Token:', token, 'User:', user, 'Authenticated:', isAuthenticated);

  // Check if the user is not authenticated or there's no token or user
  if ( !user || !token) {
    localStorage.setItem('redirectPath', location.pathname);
    console.log('Redirecting due to lack of authentication:', isAuthenticated);
    return <Navigate to="/login" />;
  }

  // If user is authenticated and both token and user are present
  console.log('Access granted to protected route');
  return children;
};

export default ProtectedRoute;
