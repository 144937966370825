import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = () => {
  const data = {
    labels: ["Total Applicants", "Total Applicants Pending", "Total Applicants Approved", "Total Applicants Declined"],
    datasets: [
      {
        data: [12, 29, 30, 10],
        backgroundColor: [
          '#03813E',
          '#FD7E01',
          '#03A74F',
          '#C60505',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, 
  };

  return (
    <div className='flex w-full  justify-center  items-center bg-white py-8 mt-6 rounded-xl' > {/* Adjust width and height here */}
   <div style={{ width: '280px', height: '280px' }}>

     <Pie data={data} options={options} />
     </div>
    </div>
  );
};

export default PieChart;
