import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className='mt-24 w-full px-5 md:px-[50px] lg:px-[100px]'>
   
    <h1 className="text-3xl font-bold mb-4 text-center">Privacy Policy</h1>
    <p className="text-gray-600 mb-4">Last updated: October 7, 2024</p>
    
    <h2 className="text-2xl font-semibold mt-6 mb-2">Introduction</h2>
    <p className="text-gray-700 mb-4">
      Welcome to our website, where we provide training and resources on technology. Your privacy is important to us, and this Privacy Policy outlines how we collect, use, and protect your information.
    </p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">Information We Collect</h2>
    <ul className="list-disc list-inside text-gray-700 mb-4">
      <li><strong>Personal Information:</strong> We may collect personal information that you provide to us directly, such as your name, email address, and any other details you submit through our forms.</li>
      <li><strong>Usage Data:</strong> We may collect information about how you access and use our website, including your IP address, browser type, pages visited, and the time spent on those pages.</li>
    </ul>

    <h2 className="text-2xl font-semibold mt-6 mb-2">How We Use Your Information</h2>
    <p className="text-gray-700 mb-4">
      We may use the information we collect for the following purposes:
    </p>
    <ul className="list-disc list-inside text-gray-700 mb-4">
      <li>To provide and maintain our services.</li>
      <li>To notify you about changes to our website or services.</li>
      <li>To allow you to participate in interactive features when you choose to do so.</li>
      <li>To provide customer support.</li>
      <li>To gather analysis or valuable information so that we can improve our website.</li>
      <li>To monitor the usage of our website.</li>
      <li>To detect, prevent, and address technical issues.</li>
    </ul>

    <h2 className="text-2xl font-semibold mt-6 mb-2">Sharing Your Information</h2>
    <p className="text-gray-700 mb-4">
      We do not sell, trade, or otherwise transfer your personal information to outside parties, except to provide services that you have requested or with your consent.
    </p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">Data Security</h2>
    <p className="text-gray-700 mb-4">
      The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
    </p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">Changes to This Privacy Policy</h2>
    <p className="text-gray-700 mb-4">
      We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
    </p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">Contact Us</h2>
    <p className="text-gray-700 mb-4">
      If you have any questions about this Privacy Policy, please contact us at:
      <br />
      <strong>Email:</strong> support@techtraining.com
    </p>
  </div>
  )
}

export default PrivacyPolicy
