import Button from "./Button";
import { navLinks } from "../constants";
import { Link, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { FaBars } from "react-icons/fa";
import {  headerLogoMobile, ideas_logo } from "../assets";

const NavBar = () => {
  const [toggle, setToggle] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [bgColor, setBgColor] = useState("bg-transparent");

  const activeHandler = (index) => {
    setActiveIndex(index);
  };


  

  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setBgColor("bg-white"); // Change this to your desired color
      } else {
        setBgColor("bg-transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section className={`w-full h-[73px] md:h-[100px] md:fixed top-0 left-0 z-30 ${bgColor}`}>
      {/* large screens  */}
      <div className="px-5 lg:px-[100px] hidden fixed top-0 md:flex items-center w-full justify-between h-[104px] p-4">
        <Link to="/" className="text-xl font-bold text-[#03813E]">
          <img src={ideas_logo} alt="Logo" />
        </Link>

        <div className="flex items-center gap-5">
          {navLinks.map((item, index) => (
            <NavLink
              key={index}
              to={item.path}
              className={({ isActive }) =>
                isActive
                  ? 'text-[#03813E] font-semibold'
                  : 'text-black hover:text-[#03813E] transition-colors'
              }
            >
              {item.label}
            </NavLink>
          ))}
        </div>

        <Button to="/apply" text="Join the Programme" />
      </div>

      <div className='h-full w-full relative flex flex-col md:hidden border-b-2 border-[#E5E5E5] z-30 py-[13px]'>
        <div className='flex h-full w-full px-[35px] items-center justify-between'>
          <Link to="/" onClick={() => setToggle(false)} className="text-xl font-bold text-[#03813E]">
          <img src={headerLogoMobile} alt="Logo" />

          </Link>
          <div className='flex items-end justify-end' onClick={() => setToggle((prev) => !prev)}>
            {toggle ? <IoCloseSharp size={24} /> : <FaBars size={24} />}
          </div>
        </div>

        <div className={`w-full bg-[#121010] bg-opacity-[0.4] h-full fixed top-0 left-0 transform ${toggle ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-400 z-40`}>
          <div className='flex items-center justify-end border-b-2 border-solid h-[52px] px-[34px]'>
            <button onClick={() => setToggle(false)}>
              <IoCloseSharp size={24} />
            </button>
          </div>

          <div className={`bg-white w-full absolute top-0 rounded-l-2xl h-full ${toggle ? 'ml-[40px]' : ''}`}>
            <div className='flex items-end justify-end mt-8 mb-10 px-20 w-full' onClick={() => setToggle((prev) => !prev)}>
              {toggle && <IoCloseSharp size={24} />}
            </div>

            <div className="flex flex-col w-full items-center justify-center gap-10">
              {navLinks.map((item, index) => (
                <div key={index} className='flex items-center flex-col justify-center gap-5'>
                  <NavLink
                    className={({ isActive }) => isActive ? 'border-b-2 text-[#03813E] border-[#03813E]' : undefined}
                    to={item.path}
                    onClick={() => setToggle(false)} // Close the navbar on link click
                  >
                    {item.label}
                  </NavLink>
                </div>
              ))}
              {/* <Link
        className="h-[50px] py-[18.5px] px-[60px] flex items-center justify-center text-white bg-[#03813E] rounded-md hover:opacity-[0.8] transition-all duration-300"
              
              onClick={() => setToggle(false)} to="/apply" >Join the Programme</Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NavBar;
