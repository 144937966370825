import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Button = ({ text, to , onClose}) => {
  const handleClose = async () => {
   
    //
    // closeSpinner
    if (onClose) onClose();
  };
  return (
    <div onClick={handleClose}>
      <Link
        to={to} // Specify the route for the link
        className="h-[50px] text-nowrap py-[18.5px] px-[60px] flex items-center justify-center text-white bg-[#03813E] rounded-md hover:opacity-[0.8] transition-all duration-300"
      >
        {text}
      </Link>

    
    </div>
  );
};

export default Button;
