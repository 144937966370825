import React from 'react';
import { useSelector } from 'react-redux';
import { total_applicant } from '../assets';

const MiddleDash = () => {
  const { statistics } = useSelector((state) => state.auth);

  const icons = Array(5).fill({ img: total_applicant });

  // Check if statistics object is available
  if (!statistics || Object.keys(statistics).length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 py-8 px-5 gap-5 mt-24 shadow-md">
      {Object.keys(statistics).map((key, index) => {
        const item = statistics[key];

        if (Array.isArray(item.count)) {
          return item.count.map((subItem, subIndex) => (
            <Card
              key={`${index}-${subIndex}`}
              label={`${item.label} - ${subItem.label || subItem.disability_type || subItem.marital_status || subItem.gender || subItem.age_group || subItem.state_of_residence || subItem.digital_skills_track || subItem.employment_status}`}
              count={subItem.count}
              icon={icons[subIndex % icons.length].img}
            />
          ));
        } else {
          return (
            <Card
              key={index}
              label={item.label}
              count={item.count}
              icon={icons[index % icons.length].img}
            />
          );
        }
      })}
    </div>
  );
};

// Simple card component
const Card = ({ label, count, icon }) => (
  <div className="p-4 border border-gray-300 rounded-lg text-center flex items-start gap-3">
    <img src={icon} alt={`${label} icon`} className="w-10 h-10 mb-2" />
   <div className='flex flex-col items-start gap-2'>
   <h4 className="font-semibold text-sm">{label}</h4>
   <p>{count}</p>
   </div>
  </div>
);

export default MiddleDash;
