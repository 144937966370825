import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className='mt-24 w-full px-5 md:px-[50px] lg:px-[100px]'>

      <h1 className="text-3xl font-bold mb-4 text-center">Terms and Conditions</h1>
      <p className="text-gray-600 mb-4">Last updated: October 7, 2024</p>
      
      <h2 className="text-2xl font-semibold mt-6 mb-2">Introduction</h2>
      <p className="text-gray-700 mb-4">
        Welcome to our website, where we provide training and resources on technology. By accessing or using our website, you agree to comply with and be bound by these Terms and Conditions.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">Use of the Website</h2>
      <p className="text-gray-700 mb-4">
        You may use our website for lawful purposes only. You agree not to use the site in any way that violates any applicable federal, state, local, or international law or regulation.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">Intellectual Property</h2>
      <p className="text-gray-700 mb-4">
        All content on this website, including text, graphics, logos, and images, is the property of our company or our content suppliers and is protected by copyright, trademark, and other intellectual property laws. You may not reproduce, distribute, or create derivative works from any content without our express written permission.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">User Accounts</h2>
      <p className="text-gray-700 mb-4">
        To access certain features of our website, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">Limitation of Liability</h2>
      <p className="text-gray-700 mb-4">
        In no event shall we, our directors, employees, partners, agents, suppliers, or affiliates be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of, or inability to access or use, our website; (ii) any conduct or content of any third party on our website; (iii) any content obtained from our website; and (iv) unauthorized access, use or alteration of your transmissions or content.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">Governing Law</h2>
      <p className="text-gray-700 mb-4">
        These Terms and Conditions shall be governed by and construed in accordance with the laws of [Your State/Country], without regard to its conflict of law principles.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">Changes to These Terms</h2>
      <p className="text-gray-700 mb-4">
        We reserve the right to modify or replace these Terms and Conditions at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our discretion.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">Contact Us</h2>
      <p className="text-gray-700 mb-4">
        If you have any questions about these Terms and Conditions, please contact us at:
        <br />
        <strong>Email:</strong> support@techtraining.com
      </p>
    </div>
  );
};

export default TermsAndConditions;
